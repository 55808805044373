:root {
  --bg_color: #fff;
  --text_color: #222;
  --hint_color: #8a8f93;
  --link_color: #0287d9;
  --button_text_color: #fff;
  --button_color: #3390ec;
  --secondary_bg_color: #f1f6f9;
  --section_bg_color: #fff;
  --accent_text_color: #0287d9;
  --subtitle_text_color: #8a8f93;
  --destructive_text_color: #cc2929;
  --header_bg_color: #527da3;
  --section_header_text_color: #8a8f93;
  --stroke_separator: #0000001a;
  --stroke_image_border: #00000014;
  --button_color_hover: #5eaeed;
  --secondary_bg_color_hover: #e5eff5;
  --hint_color_hover: #909090;
  --color_orange: #f68136;
  --overlay: #00000066;
  --bg_modal: #fff;
  --bg_header_blur: #ffffffe0;
  --bg_hover_alpha: #1313140a;
  --bg_skeleton: #f1f0f8;
  --bg_skeleton_gradient: linear-gradient(90deg,hsla(0,0%,100%,0),hsla(0,0%,100%,.75) 52.08%,hsla(0,0%,100%,0));
  --bg_ripple: #000000a3;
  --highlight-default: hsla(240,2%,56%,.24);
  --font_family: -apple-system,system-ui,"Roboto Flex",sans-serif;
  --font_family_rounded: ui-rounded,system-ui,"Roboto Flex",sans-serif;
  --border_width: 1px;
  --border_mobile: 0.5px;
  --button_border_radius: 30px;
  --input_border_radius: 12px;
  --android_input_border_radius: 28px;
  --first_layer: cubic-bezier(.44,.49,.22,1);
  --second_layer: cubic-bezier(.44,.49,.48,-0.12);
  --third_layer: cubic-bezier(.44,.49,.88,-0.12);
  --tabs_z_index: 1;
  --header_z_index: 2;
  --backdrop_z_index: 3;
  --search_z_index: 4;
  --search_results_z_index: 5;
  --header_height: 52px;
  --initial_header_height: 52px;
  --recently_launched_height: 0px;
  --initial_recently_launched_height: 0px;
  --placeholder_height: 50px
}

@media(prefers-color-scheme: dark) {
  :root {
      --bg_color:#181818;
      --text_color: #fff;
      --hint_color: #aaa;
      --link_color: #4991e5;
      --button_text_color: #fff;
      --button_color: #4991e5;
      --secondary_bg_color: #212121;
      --section_bg_color: #181818;
      --accent_text_color: #4991e5;
      --subtitle_text_color: #aaa;
      --destructive_text_color: #ee686f;
      --header_bg_color: #232326;
      --section_header_text_color: #aaa;
      --stroke_separator: #ffffff33;
      --stroke_image_border: #ffffff14;
      --button_color_hover: #64b1ee;
      --secondary_bg_color_hover: #292929;
      --hint_color_hover: #999;
      --color_orange: #ff9500;
      --overlay: #00000066;
      --bg_modal: #18181a;
      --bg_header_blur: #000000e0;
      --bg_hover_alpha: #e5e5ea14;
      --bg_skeleton: #323234;
      --bg_skeleton_gradient: linear-gradient(90deg,transparent,rgba(0,0,0,.15) 52.08%,transparent);
      --bg_ripple: #ffffffa3;
      --highlight-default: hsla(0,0%,100%,.05)
  }
}

@media(max-width: 900px) {
  :root {
      --border_width:var(--border_mobile)
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0
}

a {
  color: inherit;
  text-decoration: none
}

html {
  height: 100%
}

body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding-right: var(--scrollbar-width);
  color: var(--text_color);
  background: var(--bg_color);
  padding-top: 130px;
}

body,html {
  max-width: 100vw;
  font-family: var(--font_family);
  -webkit-font-smoothing: subpixel-antialiased
}

body.noscroll,html.noscroll {
  position: fixed;
  overflow-y: hidden;
  width: 100%;
  padding-right: var(--scrollbar-width)
}

@media(prefers-color-scheme: dark) {
  html {
      color-scheme:dark
  }
}

nav {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 24px 32px;
  background: var(--bg_color);
  z-index: 9;
}

nav .nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 800px) {
  nav .nav-header {
   width: 100%;
   margin-bottom: 8px;
  }
}

@media (max-width: 425px) {
 .logo-icon {
    width: 90%;
  }
}

@media (min-width: 800px) {
  nav {
    flex-wrap: nowrap;
  }

  nav .nav-header {
    justify-content: flex-start;
    margin-right: 16px;
  }

  nav .nav-items {
    justify-content: space-between;
  }

  nav .nav-items .search {
    margin-right: 32px;
  }
}

nav .nav-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

nav .nav-items .connect-telegram {
  height: 40px;
}

nav .nav-items .search {
  min-width: 150px;
  margin-right: 8px;
}

nav .nav-items .profile-menu {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--link_color);
  font-weight: bold;
  text-transform: uppercase;
  flex: none;
  margin-left: 16px;
}

@media (max-width: 500px) {
  .subscription-table {
    font-size: 14px;
    line-height: 1;
  }
  .lets-started-h2 {
    font-size: 14px;
    line-height: 1;
  }
  body {
    padding-top: 170px;
  }
  body .authorized {
    margin-top: -40px;
  }
  .authorized nav .nav-items {
    flex-wrap: nowrap;
  }
  nav .nav-items .search {
    margin-right: 0;
    width: 100%;
  }
  nav .nav-items .profile {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 16px;
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--accent_text_color);
    font-weight: bold;
  }
  nav .nav-items .connect-telegram {
    margin-top: 24px;
  }
  nav .nav-items {
    flex-direction: column;
    justify-content: center;
  }
}

nav .nav-items-telegram {
  flex-direction: row;
}

nav .search svg {
  position: absolute;
  top: 50%;
  left: 8px;
  width: 24px;
  transform: translateY(-50%);
  color: var(--hint_color);
  z-index: 1;
}

nav .search .search-label {
  position: relative;
  display: inline-block;
  width: 100%;
}

nav .search .search-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  width: 100%;
  background: var(--secondary_bg_color);
  display: block;
  padding: 8px 38px 8px 10px;
  border-radius: var(--input_border_radius);
  color: var(--text_color);
  caret-color: var(--link_color);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  padding-left: 38px;
  outline: none;
}
@media (max-width: 1400px) {
  nav .search .search-input {
      font-size: 17px;
      line-height: 24px;
  }
}

.main-slug {
  margin: 0 auto;
  margin-bottom: 0;
  font-size: 56px;
  font-weight: bold;
  text-align: center;
}

@media (max-width: 800px) {
  .main-slug {
    font-size: 9.5vw;
    margin: 16px auto;
  }
}

.main-slug .gradient {
  background: linear-gradient(89deg, #2b82eb, #1cc8ff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-feature-settings: "clig" off, "liga" off;
}

.buttons {
  padding-top: 20px;
  padding-bottom: 56px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.buttons .button-item {
  border-radius: var(--button_border_radius);
  border: none;
  padding: 6px 12px;
  box-sizing: border-box;
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  transition: transform .2s var(--first_layer);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: background-color .2s ease-in-out, opacity .2s ease-in-out;
  cursor: pointer;
  background-color: transparent;
  color: var(--link_color);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: bold;
}

.tabs {
  display: flex;
  margin: 0 32px;
  border-bottom: 1px solid var(--stroke_separator);
  overflow-x: auto;
}

.tabs.shadow-right {
  box-shadow: inset -25px 0px 25px -25px rgba(255,255,255,0.15);
}

.tabs.shadow-left {
  box-shadow: inset 25px 0px 25px -25px rgba(255,255,255,0.15);
}

.tabs .tab-item {
  padding: 12px;
  margin: 0 12px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  text-wrap: nowrap;
  flex: none;
  color: var(--hint_color);
  display: flex;
  align-items: center;
}

.tabs .tab-item  .mantine-Badge-root {
  width: auto;
  padding: 0 5px;
  margin-top: 3px;
  margin-left: 5px;
  font-size: 12px;
  font-weight: normal;
  color: var(--text_color);
  text-transform: lowercase;
}

@media (max-width: 500px) {
  .tabs .tab-item {
    font-size: 18px;
  }
  .table-usernames {
    /* max-width: 83vw; */
  }
}

.tabs .tab-item.active, .tabs .tab-item:hover, .tabs .tab-item:active {
  color: var(--link_color);
  border-bottom: 3px solid var(--link_color);
}

.usernames-list {
  margin: 32px;
  margin-bottom: 16px;
  overflow-x: none;
}

.pagination {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: var(--hint_color);
  font-weight: 500;
  padding-bottom: 16px;
}

.pagination .total-count {
  margin: 32px 0;
}

@media (max-width: 800px) {
  .pagination {
    justify-content: center;
  }
  .pagination .total-count {
    margin: 32px;
    text-align: center;
  }
}

.link {
  background: var(--button_color);
  border: 0;
  outline: 0;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: var(--button_border_radius);
  font-family: var(--font_family);
  color: var(--button_text_color);
  font-weight: bold;
  text-wrap: nowrap;
}

.pagination-control {
  font-family: var(--font_family);
  background: var(--button_color);
  border-radius: 5px;
  border: none;
  color: var(--button_text_color);
}

.pagination-control:where([data-active]) {
  color: var(--button_text_color);
  background: var(--button_color_hover);
}

.pagination-control:hover {
  color: var(--button_text_color);
  background: var(--button_color_hover);
}

.mantine-Table-th {
  font-weight: 500;
  font-size: 1rem;
  color: var(--text_color);
  text-align: center;
}

.mantine-Table-td {
  font-weight: bold;
  color: var(--text_color);
  overflow: auto;
  text-align: center;
}

.show-mobile {
  display: none;
}

@media (max-width: 800px) {
  .show-mobile {
    display: table-cell;
  }
  .show-desktop {
    display: none;
  }
  .mantine-Table-td, .mantine-Table-th {
    padding-left: 8px;
    padding-right: 8px;
  }
  .mantine-Table-td, .mantine-Table-th {
    text-align: center;
    text-align: -webkit-center;
  }
  .mantine-Table-td:first-child, .mantine-Table-th:first-child {
    vertical-align: top;
  }
  .mantine-Table-th {
    padding-left: 8px;
    padding-right: 8px;
    text-align: left;
  }
}

.mantine-Table-tbody :where(tr):where([data-striped='odd']:nth-of-type(odd)) {
  background-color: var(--secondary_bg_color);
}

.mantine-Table-th:where([data-with-column-border]:not(:last-child)), .m_4e7aa4f3:where([data-with-column-border]:not(:last-child)) {
  border-color: var(--stroke_separator);
}

.mantine-Table-td:where([data-with-column-border]:not(:last-child)), .m_4e7aa4f3:where([data-with-column-border]:not(:last-child)) {
  border-color: var(--stroke_separator);
}

.mantine-Table-td:last-child {
  overflow: hidden;
}

.mantine-Table-tr:where([data-with-row-border]) {
  border-color: var(--stroke_separator);
}

.fagment-button-sm {
  display: none;
}

.mantine-Badge-root {
  width: 60px;
}

.mobile-username-cell {
  padding-top: 0;
}

@media (max-width: 500px) {
  .mantine-Table-th, .mantine-Table-td {
    font-size: 15px;
  }
  .mantine-Table-th {
    font-size: 15px;
  }
}

@media (max-width: 1020px) {
  .fagment-button-md {
    display: none;
  }
  .fagment-button-sm {
    display: block;
  }
}

.table {
  overflow-x: auto;
}

.loader {
  margin: 64px auto;
  width: min-content;
}

.mantine-Overlay-root {
  position: fixed;
}

.mantine-Overlay-root > div, .mantine-Overlay-root > div > div:nth-child(2) {
  max-width: 70vh;
  margin: 0 auto;
}

footer {
  text-align: center;
  color: var(--hint_color);
  margin-bottom: 32px;
  font-size: 14px;
}

.d-flex-align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-flex-align-center svg {
  flex: none;
}

.color-accent {
  color: var(--accent_text_color);
  font-size: 15px;
}

@media (max-width: 500px) {
  .color-accent {
    font-size: 17px;
  }
}

.text-center {
  text-align: center;
}

@media (min-width: 800px) {
  .d-flex-align-center {
    justify-content: space-evenly;
  }
}

.instagram-stories {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.story-component {
  padding: 32px;
  padding-right: 0;
  width: 100%;
  height: 100%;
  background: var(--bg_color);
}

.story-component h1 {
  font-size: 1.5em;
  line-height: 1.25em;
}

.story-component h2 {
  font-size: 1em;
  line-height: 1.35em;
}

button {
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

.unlock-button {
  margin: 0 auto;
  display: block;
}

.stories-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

@media (min-width: 768px) {
  .stories-overlay {
    display: flex;
  }
}

.stories-container {
  overflow: hidden;
  width: 432px;
  height: 768px;
  border-radius: 8px;
}

.welcome, .welcome-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.welcome h1, .free-viewing h1, .subscription-options h1 {
  align-self: baseline;
  margin-bottom: 8px;
}

.welcome h2, .free-viewing h2, .subscription-options h2 {
  align-self: baseline;
  font-weight: 500;
  color: #f2a22d;
}

.welcome h2, .free-viewing h2 {
  margin-bottom: 8px;
}

@media (min-width: 390px) {
  .welcome h2 {
    margin-bottom: 32px;
  }
}
.welcome p {
  align-self: baseline;
  font-weight: 200;
}

.welcome .robot, .free-viewing .diamond {
  text-align: center;
  width: 304px;
  height: 205px;
}

.free-viewing, .free-viewing-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.free-viewing span {
  align-self: baseline;
  font-size: 1em;
}

.free-viewing p {
  align-self: baseline;
  font-size: 1em;
  font-weight: 500;
  color: #f2a22d;
}

@media (min-width: 500px) {
  .welcome-content-wrapper, .free-viewing-content-wrapper {
    width: 390px;
    height: 470px;
    justify-content: initial;
  }
}

@media (min-width: 390px) {
  .story-component {
    padding: 50px;
    padding-right: 20px;
  }

  .story-component h1 {
    font-size: 2em;
  }

  .story-component h2 {
    font-size: 1.5em;
  }
  .free-viewing span {
    font-size: 1.5em;
  }

  .free-viewing p {
    font-size: 1.2em;
  }
}

.subscription-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0;
  height: 100%;
}

.subscription-options-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: auto;
}

.subscription-options span {
  line-height: 1px;
  font-size: 2em;
  font-weight: 600;
  color: white;
  text-shadow: #f2a22d 1px 0 10px;
}

@media (min-width: 414px) {
  .subscription-options span {
    font-size: 2em;
  }
}

.subscription-table-button, .subscription-limited-button {
  position: relative;
  z-index: 1002;
}

.subscription-limited-button {
  margin: 8px 0;
}

.subscription-options .rocket-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: auto;
  padding-top: 16px;
}

.rocket {
  width: 100%;
  max-width: 250px;
  aspect-ratio: 20 / 17;
}

.subscription-options .rocket img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (min-width: 390px) {
  .subscription-options {
    padding-top: 30px;
  }
  .subscription-options .rocket {
    max-width: 305px;
  }
}

.subscription-options-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

@media (min-width: 500px) {
  .subscription-options-content-wrapper {
    width: 390px;
    justify-content: initial;
  }
}

@media (min-width: 769px) {
  .welcome-content-wrapper, .free-viewing-content-wrapper, .subscription-options-content-wrapper {
    width: 330px;
    justify-content: initial;
  }
  .subscription-options {
    padding-top: 60px;
    padding-right: 50px;
  }
  .story-component {
    padding-right: 50px;
  }
}

.stories-mobile-content {
  display: flex;
  flex-direction: column;
  margin-top: 96px;
  height: 100vh;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  outline: none;
  z-index: 1005;
}

.stories-mobile-body {
  padding-right: 30px;
  background: var(--bg_color);
  flex: 1;
}

.stories-close-wrapper {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 30px;
}

.stories-close-wrapper svg {
  width: 100%;
  height: 100%;
}